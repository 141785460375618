<template>
  <div class="job-board" :class="{ 'tfw-job-board': isTFWPublicJobBoard }">
    <CModal
      id="applyjobModal"
      title="Apply Job"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
      >{{ modalMessage }}</CModal
    >
    <div class="text-right">
      <CButton color="primary" shape="pill" @click="redirectToPublicJobBoard"
        >Back</CButton
      >
    </div>
    <div class="job-board-detail-component container-fluid">
      <div>
        <CRow class="d-flex justify-content-center">
          <img
            :src="fileSource.logo"
            class="rounded-circle"
            style="width: 60%; height: 60%"
            @error="
              $event.target.src = '/img/hospital.png';
              $event.target.style = 'width:100px; height: 100px';
            "
            alt="logo"
          />
        </CRow>
      </div>
      <div class="title">
        <p>{{ organisation }}</p>
        <h5 class="label lblspan text-primary text-center mt-2">
          Location:
          <span style="color: #00000099">{{ location }} </span>
        </h5>
      </div>
      <div class="title mt-4">
        <h5 class="text-center">
          {{ title }}
        </h5>
      </div>
      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Candidate Type</div>
          <div class="value text-left">{{ candidateType }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label text-right">Candidate Level</div>
          <p
            class="value"
            v-if="isTextWrapped(candidateLevel, 50)"
            v-c-tooltip="{
              content: candidateLevel,
            }"
          >
            {{ candidateLevel.substring(0, 50) }}...
          </p>
          <p class="value" v-else>{{ candidateLevel }}</p>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Job Reference</div>
          <div class="value text-left">{{ jobReference }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label text-right">Contract</div>
          <div class="value">{{ contract }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Date created</div>
          <div class="value text-left">{{ date_created }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label text-right">Expiry on</div>
          <div class="value">
            {{ expiryDate }} |
            <span style="font-weight: 500">{{ expiryDays }}</span>
          </div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="action-group">
        <CButton
          v-if="!getSelectedJobBoard.status"
          type="button"
          class="btn btn-primary btn-block mb-3"
          :name="`apply-job-${jobReference}`"
          :disabled="
            disableJobApplyBtn &&
            jobReference == getSelectedJobBoard.display_uid
          "
          @click="onApplyJob()"
        >
          Apply Now
        </CButton>
        <p
          v-if="getSelectedJobBoard.status && getSelectedJobBoard.status.status"
          class="text-center my-3 status"
        >
          {{ getSelectedJobBoard.status.status }}
        </p>
        <social-sharing
          :url="url"
          :title="title"
          :description="jobReference"
          inline-template
        >
          <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-share-alt" class="color-primary" />
              </div>
            </template>
            <CDropdownItem>
              <network network="email">
                <i class="fa fa-envelope"></i> Email
              </network>
            </CDropdownItem>
            <div>
              <CDropdownItem>
                <network network="facebook">
                  <i class="fab fa-facebook-square"></i> Facebook
                </network>
              </CDropdownItem>
            </div>
          </CDropdown>
        </social-sharing>
      </div>

      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 100%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
          alt="job-image"
        />
      </div>
      <div v-html="jobDescription"></div>
      <div
        class="pl-3 pr-3 mt-2"
        v-for="(item, index) in jobDocuments"
        :key="index"
      >
        For Additional Information
        <a @click="fileDownload(item)" class="cursor-pointer text-primary">
          {{ item.library_name || "Click here" }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import jobDetail from "./jobDetail.vue";
import { getLocalOrgDetail } from "@/helpers/helper";
export default {
  extends: jobDetail,
  data() {
    return {
      getLocalOrgDetail,
    };
  },
  methods: {
    redirectToPublicJobBoard() {
      let path = `/public/job-board/${getLocalOrgDetail()?.customer_uid}${
        getLocalOrgDetail()?.organisation_id
          ? `/org/${getLocalOrgDetail().organisation_id}`
          : ""
      }`;
      const { location } = this.getSelectedJobBoard;
      if (this.isTFWPublicJobBoard && location?.country_id)
        path = `/tfw/job-board/${getLocalOrgDetail()?.customer_uid}/${
          location?.country_id
        }`;
      this.$router.push({
        path,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}
</style>
